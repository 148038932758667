<template>
  <div class="p-l-md p-r-md">
    <ListFrame
      :immediateInit="false"
      :viewConf="{showReorder:false,selectAble:true,showTitle:true,showReset:true}"
      :customedParams="{contentType:laun+'Teaching'}"
      :getPageFn="getPage"
    >
      <template slot-scope="{item}">
        <TeachingItem :info="item" />
        <div>
          删除时间：
          <Time :time="item.dealTime" type="datetime" />
          &nbsp;&nbsp;&nbsp;&nbsp;删除原因：{{item.remark}}
        </div>
      </template>

      <template slot="batch-opterate" slot-scope="{items,requestFn}">
        <Button :disabled="!items.length" type="primary" @click="reWrite(items)">重传</Button>
        <TeachingForm
          :onlyForm="true"
          v-if="showForm"
          :info="editItem"
          @close="showForm=false;requestFn()"
        />
      </template>
    </ListFrame>
  </div>
</template>

<script>
import { getList } from './services/teachingService';

import TeachingForm from './TeachingForm';
export default {
  components: { TeachingForm },
  data() {
    return {
      showForm: false,
      editItem: null
    };
  },
  computed: {
    laun() {
      return this.$store.getters.getLaun;
    }
  },
  methods: {
    getPage(params) {
      return getList('recycle', params);
    },
    /**
     * @param {Array} items
     */
    reWrite(items) {
      let teachingIds = items
        .map((ele) => {
          if (ele.teachingList.length === 5) {
            // 找unit
            return ele.teachingList.find((e) => e.levelCode === 'unit');
          }
          return ele.teachingList.find((e) => e.levelCode === 'material');
        })
        .map((ele) => ele.id);

      let set = new Set(teachingIds);
      if (set.size > 1) {
        this.$Message.warning('批量重传时必须选择同一个分类下的文章');
        return;
      }
      this.showForm = true;

      this.editItem = JSON.parse(JSON.stringify(items));
    }
  }
};
</script>

<style scoped>
</style>